exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-devenir-benevole-tsx": () => import("./../../../src/pages/a-propos/devenir-benevole.tsx" /* webpackChunkName: "component---src-pages-a-propos-devenir-benevole-tsx" */),
  "component---src-pages-a-propos-faq-tsx": () => import("./../../../src/pages/a-propos/faq.tsx" /* webpackChunkName: "component---src-pages-a-propos-faq-tsx" */),
  "component---src-pages-a-propos-reglement-tsx": () => import("./../../../src/pages/a-propos/reglement.tsx" /* webpackChunkName: "component---src-pages-a-propos-reglement-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-articles-sanity-article-slug-current-tsx": () => import("./../../../src/pages/articles/{SanityArticle.slug__current}.tsx" /* webpackChunkName: "component---src-pages-articles-sanity-article-slug-current-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-courses-sanity-races-slug-current-tsx": () => import("./../../../src/pages/courses/{SanityRaces.slug__current}.tsx" /* webpackChunkName: "component---src-pages-courses-sanity-races-slug-current-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-sanity-legal-slug-current-tsx": () => import("./../../../src/pages/legal/{SanityLegal.slug__current}.tsx" /* webpackChunkName: "component---src-pages-legal-sanity-legal-slug-current-tsx" */),
  "component---src-pages-partenaires-index-tsx": () => import("./../../../src/pages/partenaires/index.tsx" /* webpackChunkName: "component---src-pages-partenaires-index-tsx" */),
  "component---src-pages-partenaires-sanity-partners-slug-current-tsx": () => import("./../../../src/pages/partenaires/{SanityPartners.slug__current}.tsx" /* webpackChunkName: "component---src-pages-partenaires-sanity-partners-slug-current-tsx" */),
  "component---src-pages-tourisme-tsx": () => import("./../../../src/pages/tourisme.tsx" /* webpackChunkName: "component---src-pages-tourisme-tsx" */),
  "component---src-templates-photos-tsx": () => import("./../../../src/templates/photos.tsx" /* webpackChunkName: "component---src-templates-photos-tsx" */)
}

